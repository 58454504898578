<template>
  <div class="records">
    <van-nav-bar title="报名记录查询"  left-text="返回"  left-arrow @click-left="onClickLeft" ></van-nav-bar>
<!--    <van-search v-model="phone" placeholder="请输入报名手机号查询" @search="onSearch" />-->
<!--    <div style="padding:5px 12px">-->
<!--      <van-button type="primary" size="small" block @click="onSearch" >搜索</van-button>-->
<!--    </div>-->

    <van-empty v-if="orders.length===-0" description="暂无数据" />
    <div v-else>
      <div style="padding: 5px 12px;" v-for="(item,index) in orders" :key="index">
        <van-cell-group :title="item.createTime">
          <van-cell title="驾校" :value="item.schoolName" />
          <van-cell title="姓名" :value="item.studentName" />
          <van-cell title="手机" :value="item.studentPhone"  />
          <van-cell title="证件号" :value="item.identifyNumber"  />
          <van-cell title="费用" :value="item.totalFee"  />
          <van-cell title="状态" :value="item.payStatusLabel"  />
          <van-cell v-for="(detail,i) in item.orderDetailList" :key="i" :title="detail.feeName" :value="detail.totalFee"  />
        </van-cell-group>
        <div style="padding: 12px 24px" v-if="item.payStatus==='not_pay'">
          <van-button :loading="loading" type="primary" block @click="toPay(item.id)">支付</van-button>
        </div>
      </div>

    </div>
    <foot></foot>
  </div>
</template>

<script>
import  {API_HOST } from '@/utils/constant'
import {Toast} from "vant";
import foot from "@/views/foot";

export default {
  name: "SignRecords",
  components: { foot },
  comments:{foot},
  data () {
    return {
      phone: '',
      orders: [],
      user: {},
      loading: false,
      type: null
    }
  },
  mounted() {
    this.loadCurrentUser()
    this.type = this.$route.query.type
  },
  methods: {
    onClickLeft() {
      this.$router.push(`/school-list?type=${this.type}`)
    },


    loadCurrentUser () {
      this.$http.get(`${API_HOST}/h5/userInfo`, {
        headers: {
          authtoken: localStorage.getItem("authtoken")
        }
      }).then((response) => {
        if (response.status === 200) {
          const res = response.data
          if (res.code === 0 && res.ok) {
            this.user = res.data
            this.onSearch()
          } else {
            Toast(res.msg)
          }
        } else {
          Toast('网络请求失败')
        }
      })
    },
    toPay (id) {
      this.loading = true
      this.$http.get(`${API_HOST}/public/payorder/${id}`).then((response) => {
        this.loading = false
        if (response.status === 200) {
          const res = response.data
          if (res.code === 0 && res.ok) {
            if (res.data.businessSuccess) {
              window.location.href = res.data.code_url
            } else {
              Toast(res.data.businessError)
            }
          } else {
            Toast(res.msg)
          }
        }  else {
          Toast('网络请求失败')
        }
      })
    },

    onSearch () {
      this.$http.get(`${API_HOST}/public/orders/iamMember/${this.user.id}`).then((response) => {
        if (response.status === 200) {
          const res = response.data
          if (res.code === 0 && res.ok) {
            this.orders = res.data
            console.log(this.orders)
          } else {
            Toast(res.msg)
          }
        }  else {
          Toast('网络请求失败')
        }
      })
    }
  }
}
</script>

<style scoped>
.records{
  display:flex;
  display: -webkit-flex;
  height:100%;
  flex-direction:column;
}
</style>
